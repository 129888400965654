<template>
   <div class="calculator-frame">

        <h2>Calcul de l'impôt sur le revenu</h2>

        <div class="calculation-datas">
            <div class="datas-section">
                <label>Calcul pour un couple fiscal ? </label>
                <select v-model="is_couple">
                    <option :value="true">OUI</option>
                    <option :value="false">NON</option>
                </select>
            </div>

            <div class="datas-section">
                <label>Nombre d'enfants à charge </label>
                <input type="number" v-model="nb_attached_children">
            </div>

            <div class="datas-section">
                <label>PFU sur les revenus financiers</label>
                <input type="checkbox" v-model="pfu_option">
            </div>
        </div>

        <table class="calculation-table-datas">
            <tr>
                <th>Catégorie de revenus</th>
                <th>Client</th>
                <th v-if="is_couple">Conjoint</th>
            </tr>

            <tr>
                <th>Revenus professionnels</th>
                <td><input type="number" v-model="user_professionnal_income"></td>
                <td v-if="is_couple"><input type="number" v-model="spouse_professionnal_income"></td>
            </tr>

            <tr>
                <th>Revenus Pension de retraite</th>
                <td><input type="number" v-model="user_retirement_income"></td>
                <td v-if="is_couple"><input type="number" v-model="spouse_retirement_income"></td>
            </tr>

            <tr>
                <th>Revenus locatif</th>
                <td :colspan="is_couple ? 2 : 1"><input type="number" v-model="real_estate_income"></td>
            </tr>

            <tr>
                <th>Dividendes</th>
                <td :colspan="is_couple ? 2 : 1"><input type="number" v-model="dividends"></td>
            </tr>
        </table>

        <div class="separator"></div>
        
        <table class="calculation-result" v-if="computationResult != undefined">
            <tr>
                <th>Nombre de parts de quotient familial</th>
                <td>{{ computationResult.FamilyQuotient.nb_part_couple + computationResult.FamilyQuotient.nb_part_dependant }}</td>
            </tr>

            <tr>
                <th>Revenu net imposable</th>
                <td>{{ toEuro(computationResult.rngComputation?.rng) }}</td>
            </tr>

            <tr>
                <th>Impôt sur le revenu (barème)</th>
                <td>{{ toEuro(computationResult.netTaxes?.taxes) }}</td>
            </tr>

            <tr>
                <th>Impôt sur le revenu (PFU)</th>
                <td>{{ toEuro(computationResult.pfu) }}</td>
            </tr>

            <tr>
                <th>Impôt total</th>
                <td>{{ toEuro(computationResult.netTaxes?.taxes + computationResult.pfu) }}</td>
            </tr>

            <tr>
                <th>Tranche marginale d'imposition</th>
                <td>{{ computationResult.tmi }} %</td>
            </tr>

            <tr>
                <th>Taux moyen d'imposition</th>
                <td>{{ Math.round((computationResult.netTaxes?.taxes / totalIncomeToScale) * 100) }} %</td>
            </tr>
        </table>
   </div> 
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            is_couple: false,
            nb_attached_children: 0,
            pfu_option: true,
            user_professionnal_income: 0,
            spouse_professionnal_income: 0,
            user_retirement_income: 0,
            spouse_retirement_income: 0,
            real_estate_income: 0,
            dividends: 0,

            computationResult: undefined,
        }
    },
    computed: {
        totalIncomeToScale() {
            let total = this.user_professionnal_income
            total += this.spouse_professionnal_income
            total += this.user_retirement_income
            total += this.spouse_retirement_income
            total += this.real_estate_income

            if (!this.pfu_option) {
                total += this.dividends
            }

            return total
        },
        requestBody() {
            let body = {
                couple: {
                    situation: (this.is_couple ? 'married' : 'alone')
                },
                persons: [
                    {
                        _id: 'user1',
                        role: 'user',
                        birthdate: new Date(),
                        is_handicaped: false,
                        is_veteran: false,
                    },
                ],
                incomes: [],
                properties: [],
                assets: [],
                societies: [],
                tax_reductions: [],
                deductible_expenses: [],
                available_tax_deduction: [],
            }

            // Membres du foyer fiscal
            if (this.is_couple) {
                body.persons.push({ _id: 'spouse1', role: 'spouse', birthdate: new Date(), is_handicaped: false, is_veteran: false, })
            }

            for (let i = 0 ; i < this.nb_attached_children ; i++) {
                body.persons.push({ _id: 'child' + i, role: 'child', birthdate: new Date(), is_handicaped: false, is_veteran: false, is_attached: true, lives_at_home: true, situation: 'alone' })
            }

            // Revenus
            if (this.user_professionnal_income > 0) {
                body.incomes.push({ _id: 'user_professionnal_income', type: 'salary', net_value: this.user_professionnal_income, person_id: 'user1', isMicro: true })
            }

            if (this.spouse_professionnal_income > 0 && this.is_couple) {
                body.incomes.push({ _id: 'spouse_professionnal_income', type: 'salary', net_value: this.spouse_professionnal_income, person_id: 'spouse1', isMicro: true })
            }

            if (this.user_retirement_income > 0) {
                body.incomes.push({ _id: 'user_retirement_income', type: 'retirement', net_value: this.user_retirement_income, person_id: 'user1', isMicro: true })
            }

            if (this.spouse_retirement_income > 0 && this.is_couple) {
                body.incomes.push({ _id: 'spouse_retirement_income', type: 'retirement', net_value: this.spouse_retirement_income, person_id: 'spouse1', isMicro: true })
            }

            if (this.real_estate_income > 0) {
                body.incomes.push({ _id: 'real_estate_income', type: 'property_income', net_value: this.real_estate_income, person_id: 'user1', isMicro: true })
            }

            if (this.dividends > 0) {
                body.incomes.push({ _id: 'dividends', type: 'dividend', net_value: this.dividends, person_id: 'user1', isMicro: true })
            }

            return {
                data: body, 
                choices: {
                    bpi_pvmo: !this.pfu_option
                }
            }
        }
    },
    watch: {
        requestBody: {
            deep: true,
            handler() {
                this.computeResult()
            }
        }
    },
    methods: {
        async computeResult() {
            
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/income-taxes', this.requestBody, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style scoped>
.calculation-datas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.datas-section {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.datas-section label {
    font-size: 22px;
    font-weight: bold;
}

.datas-section input, select {
    font-size: 22px;
}

.calculation-table-datas {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 30px;
}

.calculation-table-datas td {
    position: relative;
}

.calculation-table-datas td input {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    text-align: center;
}

.calculation-result {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    table-layout: fixed;
}

.calculation-result td, th {
    border: 1px solid lightgrey;
    padding: 10px;
    text-align: center;
}

</style>