<template>
    <div class="rh-management-page">

        <div class="add-demand-btn" @click="toggleAddDemand">
            <span>Créer une mission annexe</span>

            <modale :show="showAddDemand" @toggle="toggleAddDemand">
                <form>
                    <section>
                        <label>Titre de la mission</label>
                        <input type="text" v-model="newDemand.label">
                    </section>

                    <section>
                        <label>Nombre de demi-journée</label>
                        <input type="number" v-model="newDemand.nb_half_day">
                    </section>

                    <section>
                        <label>Date</label>
                        <input type="date" v-model="newDemand.date">
                    </section>

                    <div class="send-demand" @click="createDemand">Enregistrer</div>

                    <p>{{ errMsg }}</p>
                </form>
            </modale>
        </div>

        <select v-model="current_goal">
            <option v-for="goal in goals" :key="goal._id" :value="goal._id">{{ goal.label }}</option>
        </select>

        <select v-model="selected_ip" v-if="is_manager">
            <option v-for="ip in list_ip.filter(ip => ip.is_active)" :key="ip._id" :value="ip._id">{{ ip.firstname }} {{ ip.lastname }}</option>
        </select>

        <div class="tables-frame">
            <DemandTable
                title="Demandes validées"
                :demand_list="confirmed_demands"
                description="Retrouvez ici les missions annexes validées par votre manager"
                @confirmation="updateDemands"
            />

            <DemandTable
                title="Demandes refusées"
                :demand_list="cancelled_demands"
                description="Retrouvez ici les missions annexes refusées par votre manager"
                @confirmation="updateDemands"
            />

            <DemandTable
                title="Demandes en cours"
                :demand_list="waiting_demands"
                description="Retrouvez ici les missions annexes à valider par votre manager"
                :is_waiting="true"
                @confirmation="updateDemands"
            />

            <DemandTable
                v-if="is_manager"
                title="Demandes à valider"
                :demand_list="pending_demands"
                description="Retrouvez ici les missions annexes que vous pouvez valider"
                @confirmation="updateDemands"
            />
        </div>

        <p>Total sur la période : {{ total_days_on_period }} jours validés</p>

    </div>
</template>

<script>
import axios from 'axios'
import DemandTable from './DemandTable.vue'
export default {
    components: {
        DemandTable,
    },
    data() {
        return {
            showAddDemand: false,
            list_ip: [],
            selected_ip: undefined,
            errMsg: "",
            demand_list: [],
            pending_demands_list: [],
            newDemand: {
                nb_half_day: 1,
                label: "",
                date: new Date(),
                employeeId: undefined,
                is_confirmed: false,
                status: '',
                confirmed_by: undefined,
            },
            goals: [
                {
                    _id: 0,
                    label: 'Août 2023',
                    goal_in_points_per_day: 4,
                    start: '2023-08-01',
                    end: '2023-08-31' 
                },
                {
                    _id: 1,
                    label: 'Septembre 2023',
                    goal_in_points_per_day: 4.2,
                    start: '2023-09-01',
                    end: '2023-09-22' 
                },
                {
                    _id: 2,
                    label: 'Octobre 2023',
                    goal_in_points_per_day: 5.2,
                    start: '2023-09-23',
                    end: '2023-10-22',
                },
                {
                    _id: 3,
                    label: 'Novembre 2023',
                    goal_in_points_per_day: 4.6,
                    start: '2023-10-23',
                    end: '2023-11-23',
                },
                {
                    _id: 4,
                    label: 'Décembre 2023',
                    goal_in_points_per_day: 4.8,
                    start: '2023-11-24',
                    end: '2023-12-21',
                },
                {
                    _id: 5,
                    label: 'Janvier 2024',
                    goal_in_points_per_day: 4.9,
                    start: '2023-12-22',
                    end: '2024-01-23',
                },
                {
                    _id: 6,
                    label: 'Février 2024',
                    goal_in_points_per_day: 4.9,
                    start: '2024-01-24',
                    end: '2024-02-22',
                },
                {
                    _id: 7,
                    label: 'Mars 2024',
                    goal_in_points_per_day: 4.9,
                    start: '2024-02-23',
                    end: '2024-03-22',
                },
                {
                    _id: 8,
                    label: 'Avril 2024',
                    goal_in_points_per_day: 4.9,
                    start: '2024-03-23',
                    end: '2024-04-30',
                },
                {
                    _id: 9,
                    label: 'Mai 2024',
                    goal_in_points_per_day: 4.9,
                    start: '2024-04-23',
                    end: '2024-05-22',
                },
                {
                    _id: 10,
                    label: 'Juin 2024',
                    goal_in_points_per_day: 4.8,
                    start: '2024-05-23',
                    end: '2024-06-22',
                },
                {
                    _id: 11,
                    label: 'Juillet 2024',
                    goal_in_points_per_day: 2.4,
                    start: '2024-06-23',
                    end: '2024-07-22',
                },
                {
                    _id: 12,
                    label: 'Août 2024',
                    goal_in_points_per_day: 2.5,
                    start: '2024-07-23',
                    end: '2024-08-22',
                },
                {
                    _id: 13,
                    label: 'Septembre 2024',
                    goal_in_points_per_day: 2.5,
                    start: '2024-08-23',
                    end: '2024-09-22',
                },
                {
                    _id: 14,
                    label: 'Octobre 2024',
                    goal_in_points_per_day: 3,
                    start: '2024-09-23',
                    end: '2024-10-30',
                },
            ],
            current_goal: 14,
        }
    },
    methods: {
        async getAllIp() {
            const res = await axios.get(this.$store.getters.get_api_url + 'admins/all/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.list_ip = res.data
        },
        async createDemand() {
            this.newDemand.employeeId = this.$store.getters.getAdminId

            if (this.newDemand.label == '') {
                this.errMsg = "Veuillez saisir un label."
            } else if (this.newDemand.nb_half_hour < 1) {
                this.errMsg = "Veuillez renseigner le temps passé."
            } else if (!this.newDemand.date) {
                this.errMsg = "Veuillez renseigner une date."
            } else {
                const res = await axios.post(this.$store.getters.get_api_url + 'rh-management/demand/', this.newDemand, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                console.log(res.data)
                
                this.toggleAddDemand()
                this.getAllDemand()
            }
        },
        async getAllDemand() {
            const full_current_goal = this.goals.find(goal => goal._id == this.current_goal)
            const body = {
                start: new Date(full_current_goal.start),
                end: new Date(full_current_goal.end),
                adminId: this.selected_ip,
            }

            const res = await axios.post(this.$store.getters.get_api_url + 'rh-management/demands', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.demand_list = res.data
        },
        async getPendingDemand() {
            const full_current_goal = this.goals.find(goal => goal._id == this.current_goal)
            const body = {
                start: new Date(full_current_goal.start),
                end: new Date(full_current_goal.end),
            }

            const res = await axios.post(this.$store.getters.get_api_url + 'rh-management/pending-demands', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.pending_demands_list = res.data
        },
        updateDemands() {
            this.getAllDemand()
            this.getPendingDemand()
        },
        toggleAddDemand() {
            this.showAddDemand = !this.showAddDemand
        }
    },
    watch: {
        current_goal() {
            this.updateDemands()
        },
        selected_ip() {
            this.updateDemands()
        }
    },
    computed: {
        total_days_on_period() {
            let result = this.demand_list
                .filter(demand => demand.status == 'confirmed')
                .map(demand => demand.nb_half_day)
                .reduce((sum, current) => sum + current, 0);
            return result / 2
        },
        confirmed_demands() {
            return this.demand_list.filter(demand => demand.is_confirmed && demand.status == 'confirmed')
        },
        cancelled_demands() {
            return this.demand_list.filter(demand => demand.is_confirmed && demand.status == 'cancelled')
        },
        waiting_demands() {
            return this.demand_list.filter(demand => !demand.is_confirmed)
        },
        pending_demands() {
            const position = this.$store.getters.getAdminPosition

            return this.pending_demands_list.filter(demand => {
                if (position == 'director' || position == 'president') {
                    return true
                } else if (position == 'engineer2' && demand.employee?.position == 'engineer') {
                    return true
                } else if (position == 'engineer3' && ['engineer2', 'engineer'].includes(demand.employee?.position)) {
                    return true
                } else {
                    return false
                }
            })
        },
        is_manager() {
            const position = this.$store.getters.getAdminPosition
            if (['director', 'president', 'engineer3', 'engineer2'].includes(position)) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
        this.getAllIp()
        this.selected_ip = this.$store.state.auth.adminId
    }
}
</script>

<style scoped>
.rh-management-page {
    position: relative;
    padding: 20px 10%;
}

.tables-frame {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.add-demand-btn {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    background-color: orange;
    padding: 15px;
    border-radius: 10px;
}
.add-demand-btn:hover {
    border-radius: 0px;
    background-color: rgb(253, 193, 79);
}

form {
    width: 90%;
    margin: 10px auto;
}
form section {
    display: flex;
    flex-direction: column;
}

.send-demand {
    cursor: pointer;
    background-color: orange;
    margin: 10px auto;
    padding: 10px;
}

.send-demand:hover {
    background-color: rgb(246, 184, 69);
}

</style>