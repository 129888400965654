<template>
    <div>
        <h2>Fiscalité</h2>
        <ul>
            <li>
                <router-link to="#">Fiscalité des rachats en assurance vie</router-link>
            </li>

            <li>
                <router-link to="calculettes/impot-sur-le-revenu">Fiscalité sur le revenu</router-link>
            </li>

            <li>
                <router-link to="calculettes/impot-fortune-immobiliere">Estimation de l'IFI</router-link>
            </li>

            <li>
                <router-link to="calculettes/plus-value-immobiliere-particuliers">Fiscalité d'une plus-value immobilière (particuliers)</router-link>
            </li>
        </ul>

        <h2>Dirigeants d'entreprise</h2>
        <ul>
            <li>
                <router-link to="#">Rémunération du dirigeant</router-link>
            </li>

            <li>
                <router-link to="#">Fiscalité de plus-value sur cession d'entreprise</router-link>
            </li>
        </ul>

        <h2>Immobilier</h2>
        <ul>
            <li>
                <router-link to="calculettes/mensualite-emprunt">Mensualité d'un emprunt</router-link>
            </li>
        </ul>

        <h2>Transmission</h2>
        <ul>
            <li>
                <router-link to="calculettes/succession-simplifiee">Liquidation simple d'une succession</router-link>
            </li>

            <li>
                <router-link to="calculettes/dmtg-donation">DMTG sur une donation</router-link>
            </li>
        </ul>
    </div>
</template>

<style scoped>
a {
    color: white;
    text-decoration: none;
}

li {
    list-style-type: none;
    font-size: 22px;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgb(1, 1, 81);
}
</style>