<template>
    <form class="add-form">
        <div class="form-section">
            <label>Nom de la société</label>
            <input type="text" v-model="body.label">
        </div>

        <div class="form-section">
            <label for="type">Forme sociale</label>
            <select id="type" v-model="body.type">
                <option v-for="(value, key) in $store.getters.societyTypes" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="wealthValueMode">Valorisation patrimoniale</label>
            <input id="wealthValueMode" type="checkbox" v-model="body.wealthValueMode"/>
        </div>

        <div class="form-section" v-if="!body.wealthValueMode">
            <label>Valorisation totale</label>
            <input type="number" v-model="body.value">
        </div>

        <div class="form-section">
            <label for="wealthValueMode">Résultat imposable</label>
            <input id="wealthValueMode" type="number" v-model="body.fiscal_result"/>
        </div>

        <!-- <div class="form-section">
            <label for="wealthValueMode">Moyenne des bénéfices des 3 dernières années</label>
            <input id="wealthValueMode" type="number" v-model="body.last3yearbenefit_average"/>
        </div> -->

        <div class="form-section">
            <label for="date">Date de création</label>
            <input id="date" type="date" v-model="body.opening_date"/>
        </div>

        <detention-parts :detention="body.detention" @updated="updateDetention"/>

        <div class="form-section">
            <label for="rights">Imposition</label>
            <select id="rights" v-model="body.taxOption">
                <option v-for="(value, key) in $store.getters.taxOptions" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                label: '',
                category: "society",
                type: 'sarl',
                wealthValueMode: true,
                value: 0,
                detention: [],
                opening_date: '',
                taxOption: 'is',
                fiscal_result: 0,
                last3yearbenefit_average: 0,
                isIpCreation: false,
            },
        }
    },
    methods: {
        updateDetention(detention) {
            this.body.detention = detention
        },
        submit() {
            if (this.isNew) {
                const routeArray = this.$route.fullPath.split('/')
                if (routeArray[3] != 'profile') {
                    this.body.isIpCreation = true
                }
            }

            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type d'actif"

            } if (this.body.label == '' || this.body.label == undefined) {
                this.errorMsg = "Renseignez le nom de la société"

            }else if (this.body.detention.length < 1) {
                this.errorMsg = "Renseignez au moins un propriétaire"

            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_society', this.body)

                } else {
                    this.$store.dispatch('action_modify_society', this.body)
                }  

                this.$emit('closeModale')
            }
        },
    },
    mounted() {
        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
            this.body.opening_date = this.body.opening_date?.slice(0,10)

        } else {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

