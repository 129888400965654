<template>
    <div class="page">

        <div class="content">
            <div id="menu">
                <router-link to="family">Famille</router-link>
                <router-link to="wealth">Patrimoine</router-link>
                <router-link to="dispositions">Dispositions</router-link>
                <router-link to="taxes">Revenus & impôts</router-link>
            </div>

            <div class="vue-selection">
                <select v-model="selectedVue">
                    <option value="all-wealth">Vue globale</option>
                    <option value="family-wealth">Patrimoine familial</option>
                    <option v-for="society in $store.getters.getAllSocieties" :key="society._id" :value="society._id">Patrimoine de {{society.type.toUpperCase()}} {{ society.label }}</option>
                </select>
            </div>
            
            <LiquidityIndicator/>

            <router-view></router-view>
        </div>

        <Result/>
    </div>
</template>

<script>

import Result from '../../../results/Index.vue'
import LiquidityIndicator from './sections/LiquididyIndicator.vue'

export default {
    components: {
        Result,
        LiquidityIndicator,
    },
    data() {
        return {
            selectedVue: "all-wealth",
        }
    },
    watch: {
        selectedVue() {
            this.$store.commit('updateSelectedVue', this.selectedVue)
        }
    },
    mounted(){
        this.$store.dispatch('importScenario', this.$route.params.scenarioId)
    }
}
</script>

<style scoped>

.content {
    position: relative;
}

#menu {
    background-color: #f8f4eb;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid rgb(0, 0, 75);
}
#menu a {
    text-align: center;
    flex-basis: 25%;
    padding: 15px;
    text-decoration: none;
}
#menu a:hover {
  cursor: pointer;
  font-weight: bold;
}

a.router-link-active {
    background-color: #000762;
    color: white;
}

.vue-selection {
    position: absolute;
    left: 50px;
    top: 100px;
}

.vue-selection select {
    min-width: 400px;
    font-size: 20px;
}

</style>