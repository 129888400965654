import axios from 'axios'

export default {
    async importScenario(context, scenarioId) {
        const contract_response = await axios.get(context.getters.get_api_url + 'clients/marital-contract/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        
        context.state.marital_contract = contract_response.data

        const persons_response = await axios.get(context.getters.get_api_url + 'clients/person/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.persons = persons_response.data

        const property_response = await axios.get(context.getters.get_api_url + 'clients/property/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.properties = property_response.data

        const asset_response = await axios.get(context.getters.get_api_url + 'clients/asset/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.financial_assets = asset_response.data

        const society_response = await axios.get(context.getters.get_api_url + 'clients/society/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.societies = society_response.data

        const movable_response = await axios.get(context.getters.get_api_url + 'clients/movable/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.movable_assets = movable_response.data

        const insurance_response = await axios.get(context.getters.get_api_url + 'clients/insurance/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.insurances = insurance_response.data

        const debt_response = await axios.get(context.getters.get_api_url + 'clients/debt/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.debts = debt_response.data

        const gift_response = await axios.get(context.getters.get_api_url + 'clients/gift/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.gifts = gift_response.data

        const will_response = await axios.get(context.getters.get_api_url + 'clients/will/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.wills = will_response.data

        const reward_response = await axios.get(context.getters.get_api_url + 'clients/reward/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.rewards = reward_response.data

        const income_response = await axios.get(context.getters.get_api_url + 'clients/income/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.incomes = income_response.data

        const deductible_expense_response = await axios.get(context.getters.get_api_url + 'clients/deductible-expense/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.deductible_expenses = deductible_expense_response.data

        const tax_reduction_response = await axios.get(context.getters.get_api_url + 'clients/tax-reduction/' + scenarioId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.state.tax_reductions = tax_reduction_response.data

        // Carrière 
        const user_career_res = await axios.get(context.getters.get_api_url + 'clients/career/' + context.getters.userId, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        if (user_career_res.data) {
            context.state.user_career = user_career_res.data
        } 

        if (context.getters.spouseId) {
            const spouse_career_res = await axios.get(context.getters.get_api_url + 'clients/career/' + context.getters.spouseId, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })
            if (spouse_career_res.data) {
                context.state.spouse_career = spouse_career_res.data
            }
        }
        
    },
    updateLiquidities(context, body) {
        // Si les liquidités sont positives, ajout sur un compte courant existant ou création le cas échéant
        // Si les liquidités sont négatives, retrait d'un ou plusieurs comptes courants existants
        // Quid si les liquidités de l'investisseur ne suffisent pas à prendre en charge un achat ? idées : Créer une dette ou empécher l'investissement ? 
        console.log(body)
    },
    // CREATIONS
    async create_person(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/person/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addPerson', body)
        }

        return id
    },
    async create_property(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/property/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addProperty', body)
        }

        context.dispatch('action_buy_property', body)

        return id
    },
    async create_asset(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/asset/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addAsset', body)
        }

        context.dispatch('action_buy_asset', body)

        return id
    },
    async create_society(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/society/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addSociety', body)
        }

        context.dispatch('action_buy_society', body)

        return id
    },
    async create_movable(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/movable/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addMovable', body)
        }

        context.dispatch('action_buy_movable', body)

        return id
    },
    async create_insurance(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/insurance/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addInsurance', body)
        }

        context.dispatch('action_buy_insurance', body)

        return id
    },
    async create_debt(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/debt/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data
        body._id = id
        
        if (body.addToStore) {
            context.commit('addDebt', body)
        }

        context.dispatch('action_add_debt', body)

        return id
    },
    async create_gift(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/gift/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addGift', body)
        }

        context.dispatch('action_add_gift', body)

        return id
    },
    async create_gift_list(context, payload) {
        for (let i = 0; i < payload.gifts_list.length; i++) {
            payload.gifts_list[i].addToStore = true
            await context.dispatch('create_gift', payload.gifts_list[i])
        }

        // Modification du bien donné
            // Trouver l'actif
        if (payload.asset_id) {
            const random_gift = payload.gifts_list[0]
            const allAssets = [...context.state.societies, ...context.state.properties, ...context.state.financial_assets, ...context.state.movable_assets]
            const assetIndex = allAssets.findIndex((asset) => asset._id == payload.asset_id)
            let given_asset = JSON.parse(JSON.stringify(allAssets[assetIndex]))// Ajout des nouveaux propriétaires
            const currentDonorDetention = given_asset.detention.find(part => part.owner_id == random_gift.donor && part.right == 'pp')
            const given_part_by_donee = currentDonorDetention.part / payload.gifts_list.length
            let new_owners = []
            payload.gifts_list.forEach(gift => {
                let right = 'pp'
                if (random_gift.is_dismembered) {
                    right = 'np'
                }
    
                new_owners.push({
                    owner_id: gift.donee,
                    part: given_part_by_donee,
                    right: right
                })
            })
    
            new_owners.forEach(newPart => {
                let existing_part = given_asset.detention.find(part => part.owner_id == newPart.owner_id && part.right == newPart.right)
                if (existing_part) {
                    existing_part.part += newPart.part
                } else {
                    given_asset.detention.push(newPart)
                }
            })
    
                // Mise à jour du propriétaire actuel
            if (random_gift.is_dismembered) {
                let part = given_asset.detention.find(part => part.owner_id == random_gift.donor && part.right == 'pp')
                part.right = 'us'
            } else {
                let partIndex = given_asset.detention.findIndex(part => part.owner_id == random_gift.donor && part.right == 'pp')
                given_asset.detention.splice(partIndex, 1)
            }
    
                // Modification du bien en base de données
            given_asset.isIndivision = true
            if (random_gift.type == 'property') {
                context.dispatch('modify_property', given_asset)
            } else if (random_gift.type == 'financial') {
                context.dispatch('modify_asset', given_asset)
            } else if (random_gift.type == 'movable') {
                context.dispatch('modify_movable', given_asset)
            } else if (random_gift.type == 'dutreil' || random_gift.type == 'society'){
                context.dispatch('modify_society', given_asset)
            }
        }
    },
    async create_will(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/will/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addWill', body)
        }

        context.dispatch('action_add_will', body)

        return id
    },
    async create_income(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/income/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addIncome', body)
        }
        return id
    },
    async create_reward(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/reward/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addReward', body)
        }

        return id
    },
    async create_deductible_expense(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/deductible-expense/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addDeductibleExpense', body)
        }
        return id
    },
    async create_tax_reduction(context, body) {
        const resp = await axios.post(context.getters.get_api_url + 'clients/tax-reduction/', body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        const id = resp.data

        if (body.addToStore) {
            body._id = id
            context.commit('addTaxReduction', body)
        }
        return id
    },
    // MODIFICATIONS
    async modify_contract(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/marital-contract/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('changeContract', body)
    },
    async modify_person(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/person/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'person' })
    },
    async modify_property(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/property/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'property' })
    },
    async modify_asset(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/asset/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'asset' })
    },
    async modify_society(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/society/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'society' })
    },
    async modify_movable(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/movable/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'movable' })
    },
    async modify_insurance(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/insurance/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'insurance' })
    },
    async modify_debt(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/debt/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'debt' })
    },
    async modify_gift(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/gift/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'gift' })
    },
    async modify_will(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/will/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'will' })
    },
    async modify_reward(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/reward/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'reward' })
    },
    async modify_income(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/income/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'income' })
    },
    async modify_deductible_expense(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/deductible-expense/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'deductible_expense' })
    },
    async modify_tax_reduction(context, body) {
        await axios.put(context.getters.get_api_url + 'clients/tax-reduction/' + body._id, body, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('modify', { body: body, family: 'tax_reduction' })
    },
    // SUPPRESSIONS
    async delete_person(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/person/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'family' })
    },
    async delete_property(context, id) {
        const property = context.getters.getAllProperties.find(p => p._id == id)

        const participation_value = context.getters.getParticipationValue(property.detention, property.value)
        const participation_value_percentage = participation_value / property.value

        if (participation_value > 0) {
            const property_sell = {
                clientId         : property.clientId,
                scenarioId       : property.scenarioId,
                property_type    : property.type,
                initial_value    : property.total_payment * participation_value_percentage,
                acquisition_date : property.opening_date,
                selling_date     : new Date(),
                current_value    : participation_value,
                is_main_property : false,
            }
    
            if (property.type == 'main_property') {
                property_sell.is_main_property = true
            }
    
            await axios.post(context.getters.get_api_url + 'clients/property-sell/', property_sell, {
                headers: { authorization : 'Bearer ' + context.getters.get_token}
            })
        }

        await axios.delete(context.getters.get_api_url + 'clients/property/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })

        context.commit('delete', { id: id, family: 'property' })


        // Suppression des dettes liées avec création d'action de remboursement
        for (const debt of context.getters.getAllDebts.filter(d => d.property_id == id)) {
            context.dispatch('refund_debt', debt)
        }
    },
    async delete_asset(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/asset/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'asset' })
    },
    async delete_movable(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/movable/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'movable' })
    },
    async delete_insurance(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/insurance/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'insurance' })
    },
    async delete_society(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/society/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'society' })

        // Modification des biens immobiliers détenus par la société
        const society_properties = context.getters.getAllProperties.filter(p => p.detention.map(d => d.owner_id).includes(id))

        for (let property of society_properties) {
            property.detention = property.detention.filter(d => d.owner_id != id)

            if (property.detention.length == 0) {
                // Suppression de l'actif
                await context.dispatch('delete_property', property._id)
            } else {
                // Modification de la détention
                await context.dispatch('modify_property', property)
            }
        }

        // Modification des biens financiers détenus par la société
        const society_assets = context.getters.getAllAssets.filter(p => p.detention.map(d => d.owner_id).includes(id))

        for (let asset of society_assets) {
            asset.detention = asset.detention.filter(d => d.owner_id != id)

            if (asset.detention.length == 0) {
                // Suppression de l'actif
                await context.dispatch('delete_asset', asset._id)
            } else {
                // Modification de la détention
                await context.dispatch('modify_asset', asset)
            }
        }

        // Modification des biens mobiliers détenus par la société
        const society_movables = context.getters.getAllMovable.filter(p => p.detention.map(d => d.owner_id).includes(id))

        for (let asset of society_movables) {
            asset.detention = asset.detention.filter(d => d.owner_id != id)

            if (asset.detention.length == 0) {
                // Suppression de l'actif
                await context.dispatch('delete_movable', asset._id)
            } else {
                // Modification de la détention
                await context.dispatch('modify_movable', asset)
            }
        }

        // Modification des biens mobiliers détenus par la société
        const society_societies = context.getters.getAllSocieties.filter(p => p.detention.map(d => d.owner_id).includes(id))

        for (let asset of society_societies) {
            asset.detention = asset.detention.filter(d => d.owner_id != id)

            if (asset.detention.length == 0) {
                // Suppression de l'actif
                await context.dispatch('delete_society', asset._id)
            } else {
                // Modification de la détention
                await context.dispatch('modify_society', asset)
            }
        }

        // Modification des dettes détenus par la société
        const society_debts = context.getters.getAllDebts.filter(p => p.owner1 == id)

        for (let debt of society_debts) {
            await context.dispatch('delete_debt', debt._id)
        }
    },
    async delete_debt(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/debt/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'debt' })
    },
    async delete_gift(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/gift/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'gift' })
    },
    async delete_will(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/will/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'will' })
    },
    async delete_reward(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/reward/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'reward' })
    },
    async delete_income(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/income/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'income' })
    },
    async delete_tax_reduction(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/tax-reduction/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'tax_reduction' })
    },
    async delete_deductible_expense(context, id) {
        await axios.delete(context.getters.get_api_url + 'clients/deductible-expense/' + id, {
            headers: { authorization : 'Bearer ' + context.getters.get_token}
        })
        context.commit('delete', { id: id, family: 'deductible_expense' })
    },
}